<template>
  <header class="the-header">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

      <router-link :to="{ name: 'Home' }" class="logo d-flex align-items-center pt-3 pt-md-0">
        <img src="@/assets/images/logo-jornal.png" alt="A Tribuna" width="140">
      </router-link>

      <nav class="navbar d-none d-md-block">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }">Início</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Club' }">Clube do Assinante</router-link>
          </li>
          <li>
            <a class="nav-link" href="https://tribunaonline.com.br" target="_blank">Tribuna Online</a>
          </li>
          <li><a class="getstarted" href="#about">Assine!</a></li>
        </ul>
      </nav><!-- .navbar -->
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader'
}
</script>

<style lang="scss">
.the-header {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      a {
        display: block;
        text-align: center;
        padding: 16px;
        text-decoration: none;
      }
    }
  }
}
</style>
