<template>
  <div class="default-layout">
    <TheHeader/>
    <slot />
  </div>
</template>
<script>
import TheHeader from '@/common/components/TheHeader'
export default {
  components: { TheHeader }
}
</script>

<style lang="scss" scoped>
.default-layout {
  //background-color: red;
}
</style>
